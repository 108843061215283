// import "bootstrap/js/dist/alert";
// import "bootstrap/js/dist/button";
// import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
// import "bootstrap/js/dist/dropdown";
// import "bootstrap/js/dist/modal";
// import "bootstrap/js/dist/offcanvas";
// import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/scrollspy";
// import "bootstrap/js/dist/tab";
// import "bootstrap/js/dist/toast";
// import "bootstrap/js/dist/tooltip";

import counterUp from "counterup2";
import jQuery from "jquery";
import WOW from "wow.js";

(function ($) {
  ("use strict");

  new WOW().init();

  // Facts counter
  $(".counter").each((_, counter) => {
    counterUp(counter, {
      duration: 2000,
      delay: 10,
    });
  });
})(jQuery);
